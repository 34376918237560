import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import SystemElementSelectorComponent from "../components/systemElementSelector/systemElementSelector.componet";
import DataFromModelsComponet from "../components/dataFromModels/dataFromModels.componet";
import DataFromSensorsComponent from "../components/dataFromSensors/dataFromSensors.componet";

const RealtimePage = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 767px");

  return (
    <Fragment>
      <h3>{t("real-time-page.title")}</h3>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "20px" }}
      >
        {isMobile ? null : (
          <Grid item xs={12} sm={9} md={9}>
            <Typography>
              <span>{t("real-time-page.subtitle")}</span>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={3} md={3}></Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ height: "100%", padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <SystemElementSelectorComponent></SystemElementSelectorComponent>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ height: "100%", padding: "20px" }}>
            <DataFromModelsComponet />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DataFromSensorsComponent />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default RealtimePage;
