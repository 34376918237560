import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LineChartComponent from "shared-components/src/components/charts/lineChart.component";
import { fetchMultipleDatastreamsRequest } from "../../store/actions/realtimeDatastreamAggregateMeasure.actions";
import { getDatasets, getLabels } from "./utils";
import NoDataPlaceholder from "shared-components/src/components/noDataPlaceholder/noDataPlaceholder.component";

const HistoricalDataComponent = ({
  t,
  systemName,
  datastreamsList,
  sensors,
}) => {
  const [sensorMeasure, setSensorMeasure] = useState("");
  const dispatch = useDispatch();
  const id_thingsList = useMemo(() => {
    return sensors?.data?.map((sensor) => sensor?.attributes?.id_thing) || [];
  }, [sensors]);
  const uniqueObservedProperty = [
    ...new Set(
      datastreamsList
        ?.filter((item) => id_thingsList?.includes(item.deviceID))
        .map((item) => item.observed_property)
    ),
  ];

  const waterSystem = useSelector((state) => state.waterSystem);
  const datastreamAggregate = useSelector(
    (state) => state?.datastreamAggregateMeasure?.data
  );
  const unit = datastreamAggregate?.[0]?.unit_of_measurement || "Unità";
  const sensorChartData = useMemo(() => {
    if (!Array.isArray(datastreamAggregate) || datastreamAggregate.length === 0)
      return null;

    const reversedData = [...datastreamAggregate].reverse();

    return {
      labels: getLabels(reversedData),
      datasets: getDatasets(reversedData),
    };
  }, [datastreamAggregate]);

  const handleChangeSensor = (event) => {
    setSensorMeasure(event.target.value);
  };

  useEffect(() => {
    if (waterSystem && sensorMeasure) {
      const filteredDatastream = datastreamsList
        .filter((item) => item.name.includes("AVG_WEEKLY"))
        .filter((item) => item.observed_property === sensorMeasure)
        .filter((item) => id_thingsList?.includes(item.deviceID));

      const datastreamNames = filteredDatastream.map((ds) => ds.name);

      if (datastreamNames.length > 0) {
        dispatch(fetchMultipleDatastreamsRequest(systemName, datastreamNames));
      }
    }
  }, [
    waterSystem,
    dispatch,
    sensorMeasure,
    systemName,
    datastreamsList,
    id_thingsList,
  ]);

  useEffect(() => {
    setSensorMeasure("");
  }, [systemName]);

  return (
    <>
      <Typography variant="body1" fontWeight="bold">
        {t("real-time-page.last-Six-Month")}
      </Typography>
      <Box height="auto" width="100%" marginTop={2}>
        <FormControl
          sx={{ width: "fit-content", minWidth: "180px", marginBottom: "10px" }}
        >
          <InputLabel>
            {t("real-time-page.sensor-measure-selector-label")}
          </InputLabel>
          <Select
            labelId="sensor-select-label"
            id="sensor-select"
            value={sensorMeasure}
            label={t("real-time-page.sensor-measure-selector-label")}
            onChange={handleChangeSensor}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: "200px",
                  overflowY: "auto",
                },
              },
            }}
          >
            {uniqueObservedProperty.map((observedProperty, index) => (
              <MenuItem key={index} value={observedProperty}>
                {t(`real-time-page.observed_property.${observedProperty}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {sensorMeasure && (
          <>
            {sensorChartData?.datasets?.[0]?.data.length > 0 ? (
              <LineChartComponent data={sensorChartData} unit={unit} />
            ) : (
              <NoDataPlaceholder />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default HistoricalDataComponent;
