export const FETCH_DATASTREAM_AGGREGATE_MEASURE_REQUEST =
  "FETCH_DATASTREAM_AGGREGATE_MEASURE_REQUEST";
export const FETCH_DATASTREAM_AGGREGATE_MEASURE_SUCCESS =
  "FETCH_DATASTREAM_AGGREGATE_MEASURE_SUCCESS";
export const FETCH_DATASTREAM_AGGREGATE_MEASURE_FAILURE =
  "FETCH_DATASTREAM_AGGREGATE_MEASURE_FAILURE";

export const FETCH_MULTIPLE_DATASTREAMS_REQUEST =
  "FETCH_MULTIPLE_DATASTREAMS_REQUEST";
export const FETCH_MULTIPLE_DATASTREAMS_SUCCESS =
  "FETCH_MULTIPLE_DATASTREAMS_SUCCESS";
export const FETCH_MULTIPLE_DATASTREAMS_FAILURE =
  "FETCH_MULTIPLE_DATASTREAMS_FAILURE";

export const fetchDatastreamAggregateMeasureRequest = (
  systemName,
  sensorMeasure
) => {
  return {
    type: FETCH_DATASTREAM_AGGREGATE_MEASURE_REQUEST,
    payload: { systemName, sensorMeasure },
  };
};
export const fetchDatastreamAggregateMeasureSuccess = (data) => {
  return {
    type: FETCH_DATASTREAM_AGGREGATE_MEASURE_SUCCESS,
    payload: data,
  };
};
export const fetchDatastreamAggregateMeasureFailure = (error) => {
  return {
    type: FETCH_DATASTREAM_AGGREGATE_MEASURE_FAILURE,
    payload: error,
  };
};

export const fetchMultipleDatastreamsRequest = (
  systemName,
  datastreamNames
) => {
  return {
    type: FETCH_MULTIPLE_DATASTREAMS_REQUEST,
    payload: { systemName, datastreamNames },
  };
};
export const fetchMultipleDatastreamsSuccess = (data) => {
  return {
    type: FETCH_MULTIPLE_DATASTREAMS_SUCCESS,
    payload: data,
  };
};
export const fetchMultipleDatastreamsFailure = (error) => {
  return {
    type: FETCH_MULTIPLE_DATASTREAMS_FAILURE,
    payload: error,
  };
};
