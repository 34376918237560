import dayjs from "dayjs";

export function getLabels(sensorData) {
  if (!Array.isArray(sensorData) || sensorData.length === 0) {
    return [];
  }

  const allDates = new Set();
  sensorData.forEach((item) => {
    allDates.add(dayjs(item.time_of_measure).format("DD MM YYYY"));
  });

  return Array.from(allDates);
}

export function formattedDatastreamName(name) {
  if (!name) return "Unknown";

  const parts = name.split("_");

  const filteredParts = parts.filter((p) => p !== "AVG" && p !== "WEEKLY");

  return filteredParts.join(" ");
}

export function getDatasets(sensorData) {
  if (!Array.isArray(sensorData) || sensorData.length === 0) {
    return [];
  }

  const sortedLabels = getLabels(sensorData);

  const datastreamGroups = {};

  sensorData.forEach((item) => {
    const datastreamName = item.datastream_name || "Unknown";
    if (!datastreamGroups[datastreamName]) {
      datastreamGroups[datastreamName] = [];
    }
    datastreamGroups[datastreamName].push(item);
  });

  const colors = [
    "rgb(75, 192, 192)",
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
    "rgb(255, 206, 86)",
    "rgb(153, 102, 255)",
    "rgb(255, 159, 64)",
  ];

  const datasets = Object.entries(datastreamGroups).map(
    ([name, items], index) => {
      const valuesByDate = {};
      items.forEach((item) => {
        const dateStr = dayjs(item.time_of_measure).format("DD MM YYYY");
        valuesByDate[dateStr] = item.value;
      });

      const data = sortedLabels.map((date) => valuesByDate[date] || null);

      const colorIndex = index % colors.length;

      const formattedName = formattedDatastreamName(name);

      return {
        label: `${formattedName} (${items[0]?.unit_of_measurement || ""})`,
        data: data,
        borderColor: colors[colorIndex],
        backgroundColor: colors[colorIndex],
        borderWidth: 2,
        spanGaps: true,
      };
    }
  );

  return datasets;
}
