import { Box, Grid, Paper } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MapWaterHomeComponent from "../components/map/MapWaterHome.component";
import HomeAreaChartComponent from "shared-components/src/components/charts/homeAreaChart.component";
import WeatherForecastComponent from "shared-components/src/components/weatherForecast/WeatherForecastNew.component";
import { defaultData } from "../utils/homeChartConf";

const HomePage = () => {
  const { t } = useTranslation();
  const weatherForecast = useSelector((state) => state.weatherForecast).data;
  const waterSystems = useSelector((state) => state.waterSystems);
  const position = waterSystems?.data[0]?.attributes?.polygons[0]?.position[0];

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "5px", marginBottom: "20px" }}
      >
        {/* Weather and Overview */}
        <Grid item xs={12} md={4}>
          {weatherForecast && (
            <Paper
              variant="outlined"
              sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
            >
              <h3>{t("home.weather-forecast")}</h3>
              <div style={{ minHeight: "320px" }}>
                <WeatherForecastComponent position={position} />
              </div>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            variant="outlined"
            sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
          >
            <h3>{t("home.oveview")}</h3>
            <MapWaterHomeComponent />
          </Paper>
        </Grid>
        {/* Forecast and Flow projection */}
        <Grid item xs={12} md={4}>
          <Paper
            variant="outlined"
            sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
          >
            <h3>{t("home.months-water-forecast")}</h3>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="10px"
            >
              <HomeAreaChartComponent />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            variant="outlined"
            sx={{
              height: "100%",
              paddingX: "20px",
              paddingBottom: "20px",
            }}
          >
            <h3>{t("home.mounths-flow-projection")}</h3>
            <HomeAreaChartComponent data={defaultData} beginAtZero />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HomePage;
