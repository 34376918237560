import axios from "axios";
import { API_GET_DATASTREAM_AGGREGATE_MEASURE } from "../../config/api";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import dayjs from "dayjs";
import {
  FETCH_DATASTREAM_AGGREGATE_MEASURE_REQUEST,
  FETCH_MULTIPLE_DATASTREAMS_REQUEST,
  fetchDatastreamAggregateMeasureFailure,
  fetchDatastreamAggregateMeasureSuccess,
  fetchMultipleDatastreamsFailure,
  fetchMultipleDatastreamsSuccess,
} from "../../store/actions/realtimeDatastreamAggregateMeasure.actions";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";

const getDatastreamAggregateMeasure = async (token, thing, name, time) => {
  const url = `${API_GET_DATASTREAM_AGGREGATE_MEASURE}?thing=${thing}&name=${name}&time=${time}`;
  const headers = {
    "content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

function* handleDatastreamAggregateMeasure(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const thing = action?.payload.systemName;
    const name = action?.payload.sensorMeasure;
    const time = dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const response = yield call(
      getDatastreamAggregateMeasure,
      token,
      thing,
      name,
      time
    );
    yield put(fetchDatastreamAggregateMeasureSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchDatastreamAggregateMeasureFailure(error.message));
  }
}

function* handleMultipleDatastreams(action) {
  try {
    yield put(showProgress());
    const { systemName, datastreamNames } = action.payload;
    const time = dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const token = yield select((state) => state.auth.token);

    const calls = datastreamNames.map((name) =>
      call(getDatastreamAggregateMeasure, token, systemName, name, time)
    );

    const results = yield all(calls);

    const combinedResults = results.flat();

    yield put(fetchMultipleDatastreamsSuccess(combinedResults));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchMultipleDatastreamsFailure(error.message));
  }
}

export function* watchGetDatastreamAggregateMeasure() {
  yield takeLatest(
    FETCH_DATASTREAM_AGGREGATE_MEASURE_REQUEST,
    handleDatastreamAggregateMeasure
  );
}

export function* watchGetMultipleDatastreams() {
  yield takeLatest(
    FETCH_MULTIPLE_DATASTREAMS_REQUEST,
    handleMultipleDatastreams
  );
}
