import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HistoricalDataComponent from "./HistoricalData.component";
import LastDataComponent from "./LastData.component";
import { fetchWaterSensorsMeasureRequest } from "../../store/actions/realtimeWater.actions";
import { fetchDatastreamListRequest } from "../../store/actions/realtimeDatastreamList.actions";

const formatSensorData = (item, t, index) => {
  const unit_of_measurement =
    item.unit_of_measurement.match(/\(([^)]+)\)/)?.[1] || "";
  const propertyName = t(`real-time-page.property-name.${item.property_name}`);
  const value = `${item.value} ${unit_of_measurement}`.trim();

  return {
    unit_of_measurement,
    name: propertyName,
    value,
    key: index,
  };
};

const DataFromSensorsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sensor, setSensor] = useState("");

  const waterSystems = useSelector((state) => state?.waterSystems?.data);
  const waterSystemSelected = useSelector((state) => state?.waterSystem?.data);
  const sensorsMeasure = useSelector(
    (state) => state?.waterSensorsMeasure?.data || []
  );
  const datastreamsList = useSelector((state) => state?.datastreamList?.data);

  const system = waterSystems.find((ws) => ws.id === waterSystemSelected);
  const sensors = system?.attributes?.sensors;
  const selectedSensor = sensors?.data.find(
    (s) => s.attributes.label === sensor
  );
  const sensorIdThing = selectedSensor
    ? selectedSensor.attributes.id_thing
    : null;

  const formattedData = useMemo(() => {
    if (!Array.isArray(sensorsMeasure) || !sensorIdThing) {
      return [];
    }

    return sensorsMeasure
      .filter(
        (item) =>
          item !== null &&
          item.deviceID === sensorIdThing &&
          !item.datastream_name.includes("AVG_WEEKLY")
      )
      .map((item, index) => formatSensorData(item, t, index));
  }, [sensorsMeasure, sensorIdThing, t]);

  const handleChangeMeasure = (event) => {
    setSensor(event.target.value);
  };

  useEffect(() => {
    if (waterSystemSelected) {
      dispatch(fetchWaterSensorsMeasureRequest(system?.attributes?.id_thing));
      dispatch(fetchDatastreamListRequest(system?.attributes?.id_thing));
    }
    setSensor(null);
  }, [dispatch, system?.attributes?.id_thing, waterSystemSelected]);

  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <Typography variant="h6" fontWeight="bold" paddingBottom={2}>
        {t("real-time-page.data-from-sensors")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper variant="outlined" sx={{ height: "100%", padding: "20px" }}>
            <HistoricalDataComponent
              t={t}
              systemName={system?.attributes?.id_thing}
              waterSystems={waterSystems}
              sensor={sensor}
              datastreamsList={datastreamsList}
              sensors={sensors}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper variant="outlined" sx={{ height: "100%", padding: "20px" }}>
            <LastDataComponent
              t={t}
              sensor={sensor}
              handleChangeMeasure={handleChangeMeasure}
              sensorData={formattedData || ""}
              waterSystems={waterSystems}
              system={system}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataFromSensorsComponent;
