import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setWaterSystem } from "../../store/actions/waterSystem.actions";

const SystemElementSelectorComponent = (props) => {
  const { t } = useTranslation();

  const waterSystemSelected = useSelector((state) => state?.waterSystem?.data);
  const waterSystems = useSelector((state) => state?.waterSystems?.data);
  const [value, setValue] = React.useState(waterSystemSelected ?? "");

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
    dispatch(setWaterSystem(event.target.value));
  };
  const label = t("real-time-page.system-element-selector-label");

  return (
    <Box sx={{ minWidth: 120 }}>
      {!waterSystemSelected && (
        <div
          style={{ fontWeight: "bold", marginBottom: "5px", fontSize: "11px" }}
        >
          {t("real-time-page.system-element-selector-label")}
        </div>
      )}
      <FormControl fullWidth>
        <InputLabel id="system-element-select-label">{label}</InputLabel>
        <Select
          labelId="system-element-select-label"
          id="system-element-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {waterSystems &&
            waterSystems.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option?.attributes?.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SystemElementSelectorComponent;
