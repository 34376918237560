import { Button, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { updateUserRequest } from "../store/actions/users.actions";

export const useColumns = (dispatch) => {
  const { t } = useTranslation();
  const handleButtonClick = (user) => {
    user = {
      id: user.id,
      confirmed: true,
      active_dashboard_water: !user?.active_dashboard_water,
    };
    dispatch(updateUserRequest(user));
  };

  return [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "active_dashboard_water",
      headerName: t("users-page.active"),
      width: 120,
      renderCell: (params) => (
        <Tooltip
          title={
            params.value ? t("users-page.active") : t("users-page.not_active")
          }
        >
          <CircleIcon
            sx={{ color: params.value ? "green" : "red" }}
            fontSize="small"
          />
        </Tooltip>
      ),
    },
    { field: "name", headerName: t("users-page.name"), width: 150 },
    { field: "surname", headerName: t("users-page.surname"), width: 150 },
    { field: "email", headerName: t("users-page.email"), width: 150 },
    { field: "notes", headerName: t("users-page.notes"), width: 300 },
    {
      field: "action",
      headerName: t("users-page.action"),
      width: 150,
      renderCell: (params) => (
        <Button
          variant={
            !params.row.active_dashboard_water ? "contained" : "outlined"
          }
          color={"primary"}
          onClick={() => {
            handleButtonClick(params.row);
          }}
          fullWidth
        >
          {params.row.active_dashboard_water
            ? t("users-page.disapprove")
            : t("users-page.approve")}
        </Button>
      ),
    },
  ];
};
