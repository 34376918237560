import {
  Alert,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircularProgressComponent from "shared-components/src/components/feedback/CircularProgress.component";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchWaterAuthoritiesRequest } from "../../store/actions/waterAuthorities.actions";
import { signupRequest } from "../../store/actions/signup.actions";

const ActivationRequestComponent = (props) => {
  const { logo } = props;
  const { t } = useTranslation();
  const error = useSelector((state) => state?.signup?.error);
  const success = useSelector((state) => state?.signup?.success);
  const waterAuthorities = useSelector((state) => state.waterAuthorities.data);
  const dispatch = useDispatch();
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  useEffect(() => {
    dispatch(fetchWaterAuthoritiesRequest());
  }, [dispatch]);

  const initialValues = {
    name: userData.name,
    surname: userData.surname,
    email: userData.email || "",
    notes: "",
    water_authority: "",
    isSignupPage: false,
  };

  const errorMessages = {
    required: t("signup_page.required"),
    emailInvalid: t("signup_page.emailInvalid"),
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(errorMessages.emailInvalid)
      .required(errorMessages.required),
    water_authority: Yup.string().required(errorMessages.required),
  });

  const handleSignup = (value) => {
    value = { ...value, username: value.email };
    dispatch(signupRequest(value));
  };

  return (
    <Fragment>
      <CircularProgressComponent />
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {logo && <img src={logo} style={{ maxWidth: "inherit" }} alt="" />}
          <h4>{t("activation_request_page.activation_request")}</h4>
        </Grid>
        <Grid item xs={12}>
          {error && (
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {t(`signup_page.errors.${error}`)}
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignup}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label="Email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      error={errors.email && touched.email}
                      helperText={errors.email}
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={t("signup_page.notes")}
                      placeholder={t("signup_page.notes_placeholder")}
                      name={`notes`}
                      variant="outlined"
                      multiline
                      minRows={3}
                      fullWidth
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="water_authority">
                      {({ field, form }) => (
                        <Select
                          {...field}
                          onChange={(event) =>
                            form.setFieldValue(
                              "water_authority",
                              event.target.value
                            )
                          }
                          variant="outlined"
                          fullWidth
                          displayEmpty
                          error={
                            errors.water_authority && touched.water_authority
                          }
                          helperText={errors.water_authority}
                          disabled={success}
                        >
                          <MenuItem value="" disabled>
                            {t("signup_page.select_water_authority")}
                          </MenuItem>
                          {waterAuthorities?.data?.map((authority) => (
                            <MenuItem key={authority.id} value={authority.id}>
                              {authority.attributes.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    {!success && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        {t("activation_request_page.send_request")}
                      </Button>
                    )}
                    {success ? (
                      <Alert
                        severity="success"
                        style={{ marginBottom: "16px" }}
                      >
                        {t("signup_page.signup_success")}
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ActivationRequestComponent;
