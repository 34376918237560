export const routerPaths = {
  login: "/login",
  home: "/home",
  setup: "/setup",
  setupSensors: "/setup-sensors",
  setupWaterSystems: "/setup-water-systems",
  overview: "/overview",
  realtime: "/realtime",
  wiki: "/wiki",
  signup: "/signup",
  users: "/users",
  activation_request: "/activation_request",
  forgot_password: "/forgot-password",
  reset_password: "/reset-password",
};
