import {
  Alert,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressComponent from "shared-components/src/components/feedback/CircularProgress.component";
import { signupRequest } from "../../store/actions/signup.actions";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { fetchWaterAuthoritiesRequest } from "../../store/actions/waterAuthorities.actions";

const SignupComponent = (props) => {
  const { logo } = props;
  const [showPassword, setShowPassword] = useState(false);
  const error = useSelector((state) => state?.signup?.error);
  const alreadyRegistered = useSelector(
    (state) => state.signup.alreadyRegistered
  );
  const success = useSelector((state) => state?.signup?.success);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const waterAuthorities = useSelector((state) => state.waterAuthorities.data);
  useEffect(() => {
    dispatch(fetchWaterAuthoritiesRequest());
  }, [dispatch]);

  const handleSignup = (value) => {
    value = { ...value, username: value.email };
    const userData = {
      email: value.email,
      name: value.name,
      surname: value.surname,
    };
    sessionStorage.setItem("userData", JSON.stringify(userData));
    dispatch(signupRequest(value));
  };

  const initialValues = {
    name: "",
    surname: "",
    email: "",
    password: "",
    confirm_password: "",
    water_authority: "",
    isSignupPage: true,
  };

  const errorMessages = {
    required: t("signup_page.required"),
    emailInvalid: t("signup_page.emailInvalid"),
    passwordMismatch: t("signup_page.passwordMismatch"),
    passwordMin: t("signup_page.passwordMin"),
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(errorMessages.required),
    surname: Yup.string().required(errorMessages.required),
    email: Yup.string()
      .email(errorMessages.emailInvalid)
      .required(errorMessages.required),
    password: Yup.string()
      .min(8, errorMessages.passwordMin)
      .required(errorMessages.required),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], errorMessages.passwordMismatch)
      .required(errorMessages.required),
    water_authority: Yup.string().required(errorMessages.required),
  });

  const alreadyRegisteredInFarm = (alreadyRegistered) => {
    return alreadyRegistered?.active_platform === "farm";
  };

  return (
    <Fragment>
      {/* <AlertInfoComponent /> */}
      <CircularProgressComponent />
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {logo && <img src={logo} style={{ maxWidth: "inherit" }} alt="" />}
          <h4>{t("signup_page.signup")}</h4>
          <h5>{t("signup_page.signup_subtitle")}</h5>
        </Grid>
        <Grid item xs={12}>
          {error && (
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {t(`signup_page.errors.${error}`)}
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignup}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container rowSpacing={2} columnSpacing={2} style={{}}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={t("signup_page.name")}
                      name={`name`}
                      variant="outlined"
                      fullWidth
                      error={errors.name && touched.name}
                      helperText={errors.name}
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={t("signup_page.surname")}
                      name={`surname`}
                      variant="outlined"
                      fullWidth
                      error={errors.surname && touched.surname}
                      helperText={errors.surname}
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={t("signup_page.email")}
                      name={`email`}
                      variant="outlined"
                      fullWidth
                      error={errors.email && touched.email}
                      helperText={errors.email}
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type={showPassword ? "text" : "password"}
                      as={TextField}
                      label={t("signup_page.password")}
                      name={`password`}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                      fullWidth
                      error={errors.password && touched.password}
                      helperText={errors.password}
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type={showPassword ? "text" : "password"}
                      as={TextField}
                      label={t("signup_page.confirm_password")}
                      name={`confirm_password`}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                      fullWidth
                      error={
                        errors.confirm_password && touched.confirm_password
                      }
                      helperText={errors.confirm_password}
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="water_authority">
                      {({ field, form }) => (
                        <Select
                          {...field}
                          onChange={(event) =>
                            form.setFieldValue(
                              "water_authority",
                              event.target.value
                            )
                          }
                          variant="outlined"
                          fullWidth
                          displayEmpty
                          error={
                            errors.water_authority && touched.water_authority
                          }
                          helperText={errors.water_authority}
                          disabled={success}
                        >
                          <MenuItem value="" disabled>
                            {t("signup_page.select_water_authority")}
                          </MenuItem>
                          {waterAuthorities?.data?.map((authority) => (
                            <MenuItem key={authority.id} value={authority.id}>
                              {authority.attributes.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={t("signup_page.notes")}
                      placeholder={t("signup_page.notes_placeholder")}
                      name={`notes`}
                      variant="outlined"
                      multiline
                      minRows={3}
                      fullWidth
                      disabled={success}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {success ||
                    (alreadyRegisteredInFarm(alreadyRegistered) &&
                      error) ? null : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        {t("signup_page.signup")}
                      </Button>
                    )}
                    {success ? (
                      <Alert
                        severity="success"
                        style={{ marginBottom: "16px" }}
                      >
                        {t("signup_page.signup_success")}
                      </Alert>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {alreadyRegisteredInFarm(alreadyRegistered) && error ? (
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          href="/activation_request"
                        >
                          {t("signup_page.activation_request.access_request")}
                        </Button>
                        <Alert severity="info" style={{ marginBottom: "16px" }}>
                          {t("signup_page.activation_request.activation_info")}
                        </Alert>
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SignupComponent;
